.button {
    width: 100%;
    max-width: 200px;
	display: block;
	padding: 1em 2em;
	border: none;
	background: none;
	color: #fff;
	position: relative;
	z-index: 1;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
    background-color: var(--powder-pink);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    filter: drop-shadow(3px 3px 0px var(--dark-powder-pink));
    transition: all 300ms;
}

.buttonAnchor {
    text-decoration: none;
    color: #fff;
}

.button:focus {
	outline: none;
}

.buttonIcon {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;
}

.buttonLable {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding-left: 5px;
}

.button:hover {
    background-color: var(--button-hover-overlay);
    filter: drop-shadow(3px 3px 0px var(--powder-pink));
}

/* .buttonLable:hover {
} */