.footerWraper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;    
}

.footerContainer {
    background-color: var(--dark-black-grey);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 400px;
}

.footerLeftContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex: 2;
}

.footerDetailsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    height: 100%;
    width: 100%
}

.footerRightContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    padding: 25px;
    box-sizing: border-box;
}
.footerRightContainer > div > h2 {
    font-size: clamp(25px, 3vw, 40px);
    color: #fff;
    font-weight: 600;
 }

 .footerRightContainer > div > p {
     color: var(--light-grey);
     font-size: clamp(12px, 2vw, 16px);
 }

.footerFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 20px 0px 20px;
    position: relative;
    width: 100%;
}


.formInputContainer {
    width: 100%;
    height: 100%;
}

.footerFormHeader {
    color: white;
    font-size: 20px;
    font-weight: 500;
}

.footerFormName {
    margin-top: 15px;
}

.footerFormContact {
    margin: 30px 0px;
}

.footerFormDescription {
    margin: 30px 0px;
    height: 80px;
    padding-right: 15px;
    padding-bottom: 5px;
}

.footerFormButton {
    margin-top: 25px;
}

.footerFormSendButton {
    width: 100%;
    height: 40px;
}

.footerFormTextarea {
    height: 100%;
    width: 100%;
    font: 14px/24px "Lato", Arial, sans-serif;
    color: var(--light-grey);
    padding-left: 10px;
    resize: none;
}

.socialHeader {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
}

.EmailHeader {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.socailLinkContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
    justify-content: center;
}

.socailLinkContainer > a {
    margin-right: 30px;
}

.socailLinkContainer > a:last-of-type {
    margin-right: 0px;
}


.socailLinkIcon {
    color: #fff;
    filter: drop-shadow(1px 2px 0px var(--powder-pink));
    transition: all 300ms;
}

.socailLinkIcon:hover {
    color: var(--powder-pink);
    filter: drop-shadow(1px 2px 0px #fff);
}

.emailIdContianer {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 20px 20px;
    box-sizing: border-box;
    justify-content: center;
    width: 100%;
}

.emailIdContianer > a {
    text-decoration: none;
    text-decoration-skip-ink: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.emailContainer {
    color: white;
    font-family: var(--font-mono);
    font-size: clamp(18px, 3vw, 20px);
    padding-bottom: 5px;
    letter-spacing: 0.5px;
}

.emailContainer:hover {
    text-decoration: underline;
}

.footerNoteContainer {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.footerCopyRight {
    font-size: 12px;
    letter-spacing: 0.5px;
    color: var(--light-grey);
}

.copyRrightIcon {
    margin-right: 10px;
}

@media (min-width: 768px) {
    .footerLeftContainer {
        border-right: none;
    }

    .emailIdContianer {
        justify-content: flex-start;
    }

    .socailLinkContainer {
        justify-content: flex-start;
    }
}

.customShapeDividerBottomFooter {
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    margin-bottom: -2px;
}

.customShapeDividerBottomFooter svg {
    position: relative;
    display: block;
    width: calc(109% + 1.3px);
    height: 132px;
}

.customShapeDividerBottomFooter .shapeFillFooter {
    fill: var(--dark-black-grey);
}

h1, h2, h3, h4, h5, h6 {
    margin: 0px 0px 10px;
    line-height: 1.1;
}