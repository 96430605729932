.contentContainer {
    background: var(--powder-pink);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.projectsContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 20px 50px;
    box-sizing: border-box;
    flex-wrap: wrap;
    max-width: 1000px;
}

@media (max-width: 768px) {
    .contentContainer {
        flex-wrap: wrap;
    }
    .projectsContainer {
        flex-wrap: wrap;
        padding: 20px 20px;
    }
}

.project {
    display: grid;
    grid-template-columns: 4fr 5fr;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    gap: 20px;
}

.projectDescWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    margin: 0px;
}

@media (max-width: 425px) {
    .project {
        grid-template-columns: 1fr;
    }

    .projectText {
        padding: 10px !important;
    }

    .projectOverlay {
        display: none !important;
    }
}

.projectCard {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.projectOverlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    opacity: 0;
    border-radius: 5px;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.projectCard:hover .projectOverlay {
    opacity: 1;
    backdrop-filter: blur(3px);
}

.projectImage {
    width: 100%;
    height: 100%;
}

.projectImage img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.projectDetails {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    top: 50%;
    left: 50%;
    margin: 0;
    border: none;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.projectDescription {
    height: 100%;
}

.projectDescription p {
    margin: 0;
    padding: 0;
    text-align: left;
    color: #fff;
}

.projectDescription h2 {
    color: #fff;
    margin: 0px;
    padding: 0px;
}

.techStack {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    padding: 0px;
    margin: 20px 0px 0px;
    overflow: hidden;
    list-style: none;
    color: #fff;
}

.techStack > li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
    font-family: var(--font-mono);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.techStack > li::before {
    content: "»";
    position: absolute;
    left: 0px;
    color: #fff;
    font-size: 16px;
    line-height: 12px;
}

.projectText {
    border-radius: 5px;
    padding: 20px;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    box-shadow: var(--card-shadow);
    background: var(--card-background-gradient);
}

.projectCard:hover .projectDetails {
    top: 50%;
    left: 50%;
    opacity: 1;
}

.projectTitle {
    color: white;
    font-size: 25px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.projectButtonIcon {
    color: var(--dark-powder-pink);
    transition: color 200ms;
}

.projectButtonIcon:hover {
    color: #fff;
}

.projectButtonsContianer {
    gap: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}

.fadeInBottom{
  top: 80%;
}

.projectsHeader {
    color: #FFF;
    margin-bottom: 2rem;
}

.projectsHeader > h2 {
    color: #FFF;
    font-size: clamp(26px,5vw,32px);
    margin: 0px;
}

.projectHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}