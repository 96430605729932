.homeContainer {
    min-height: 100vh;
    width: 100%;
    margin: 0px auto;
    position: relative;
}

.landing {
    min-height: 100vh;
}

.about {
    height: auto;
    padding: 100px 0px;
}

.experience {
    height: auto;
    padding: 100px 0px;
}

.content {
    min-height: 50vh;
    height: auto;
    padding-bottom: 100px;
}

.footer {
    height: auto;
}

.parentOverlay {
    content: '';
    height: 100%;
    width: 100%;
    margin: 0px auto;
    backdrop-filter: blur(0px);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 31;
    transition: backdrop-filter 0.25s cubic-bezier(0.645,0.045,0.355,1);
}