.aboutContainer {
    background-color: var(--powder-pink);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 75px;
    box-sizing: border-box;
}

.aboutSection {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    max-width: 900px;
    flex-wrap: wrap;
}

.aboutHeader {
    width: 100%;
}

.aboutText {
    color: #fff;
    flex: 1;
}

.paragraphStyle {
    text-align: justify;
    margin: 0px 0px 15px;
}

.paragraphStyle > a {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    position: relative;
    transition: var(--common-transition);
    color: var(--dark-powder-pink);
    font-weight: 700;
}

.paragraphStyle > a::after {
    content: "";
    display: block;
    width: 0px;
    height: 1px;
    position: relative;
    bottom: 0.10em;
    background-color: var(--dark-powder-pink);
    transition: var(--common-transition);
    opacity: 0.5;
}

.paragraphStyle > a:hover, .paragraphStyle > a:focus, .paragraphStyle > a:active {
    color: var(--dark-powder-pink);
    outline: 0px;
}

.paragraphStyle > a:hover::after, .paragraphStyle > a:focus::after, .paragraphStyle > a:active::after { 
    width: 100%; 
  }

.stackContainer {
    height: 100%;
    width: 100%;
    max-width: 300px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex: 1;
}

.imageGrid {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 10px 30px -15px var(--dark-black-grey);
    position: relative;
    transition: var(--common-transition);;
}

.imageGrid::after {
    display: inline-block;
    content: " ";
    background: var(--profile-pick-backdrop);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 20px;
    top: 20px;
    border-radius: 4px;
    z-index: 0;
    transition: var(--common-transition);
}

.imageGrid:hover::after {
    transform: translate(-5px, -5px);
}

.imageGrid > img {
    object-fit: cover;
    height: 100%;
    width: 100%;    
    z-index: 1;
    position: relative;
    border-radius: 4px;
}

.itemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.imageContainer {
    box-sizing: border-box;
}

.detailsContainer {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;

}

.percentageBarContainer {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
}

.percentageBarOuter {
    width: 100%;
    border: 1px solid #f5005752;
    height: 15px;
    display: flex;
    border-radius: 30px;
    justify-content: flex-start;
    align-items: center;
    border-left: none;
    box-shadow: inset 0px 0px 2px 0px #5f5f5f;
}

.percentageBarInner {
    background-color: #ffffff;
    border: none;
    height: 100%;
    border-radius: 30px;
    background-image: linear-gradient(1deg, var(--dark-black-grey) 11.2%, var(--powder-pink) 92.5% );
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.progressBarValue {
    color: white;
    font-size: 8px;
    font-weight: 500;
    margin-right: 5px;
}

.techIcons {
    filter: drop-shadow(0px 2px 0px var(--powder-pink));
}

@media (max-width: 425px) {
    .aboutContainer {
        padding: 20px;
    }
}

@media (max-width: 768px) {

    .aboutSection {
        flex-wrap: wrap;
    }

    .aboutText, .stackContainer {
        flex: auto;
    }
}

.aboutHeaderContainer  {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    max-width: 900px;
}

.aboutHeader{
    margin-top: 0px;
    text-align: left;
    margin-bottom: 2rem;
}

.aboutHeader > h2 {
    color: #FFF;
    font-size: clamp(26px,5vw,32px);
    margin: 0px;
}

.skillsList {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    padding: 0px;
    margin: 20px 0px 0px;
    overflow: hidden;
    list-style: none;
}

.skillsList > li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
    font-family: var(--font-mono);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.skillsList > li::before {
    content: "»";
    position: absolute;
    left: 0px;
    color: #fff;
    font-size: 16px;
    line-height: 12px;
}
