.landingContainer {
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background-color: var(--dark-black-grey);
    position: relative;
    padding: 0px 50px;
}

.landingTitle {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 110px;
}

.profileImageContianer {
    color: white;
    display: flex;
    align-items: center;
    z-index: 5;
    position: absolute;
    top: 20px;
    left: 40px;
}

.profileImage {
    height: 50px;
    width: 50px;
    border: 2px solid white;
    border-radius: 50px;
    overflow: hidden;
}

.profileImageTag {
    height: 50px;
    width: 50px;
}

.name {
    grid-area: text;
    color: #ffffff;
    text-shadow: 0vmin 1vmin 0px var(--powder-pink);
}

.landingText {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}

.resumeButtonContainer {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.landingText > h1 {
    font-size: clamp(14px, 5vw, 16px);
    margin: 0px 0px 10px 4px;
    font-weight: 400;
}

.landingText > h2 {
    font-size: clamp(40px, 8vw, 80px);
    margin: 0px;
}

.landingText > h3 {
    font-size: clamp(40px, 8vw, 80px);
    margin: 0px;
}

.subText {
    font-size: 50px;
    font-weight: 700;
    display: flex;
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.1;
}

.metaLine {
    width: 100%;
    color: var(--light-grey);
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.metaLine > h1 {
    font-size: clamp(14px, 5vw, 16px);
    font-weight: 400;
    font-family: var(--font-mono);
    margin: 10px 0px 0px 4px;
    text-align: left;
}

.floatingShapesOne {
    height: 225px;
    width: 225px;
    position: absolute;
    background: linear-gradient(to right bottom, rgba(245 0 87 / 80%), rgb(245 0 87 / 14%));
    left: 60%;
    top: 20%;
    border-radius: 50%;
}

.floatingShapesTwo {
    height: 225px;
    width: 225px;
    position: absolute;
    background: linear-gradient(to right bottom, rgba(245 0 87 / 80%), rgb(245 0 87 / 22%));
    left: 70%;
    border-radius: 50%;
    top: 35%;
}

.floatingShapesThree {
    height: 100px;
    width: 100px;
    position: absolute;
    background-color: var(--powder-pink);
    left: 60%;
    top: 20%;
}

.floatingShapesFour {
    height: 100px;
    width: 100px;
    position: absolute;
    background-color: var(--powder-pink);
    left: 60%;
    top: 20%;
}

.customShapeDividerBottom {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}


.customShapeDividerBottom svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 136px;
    transform: rotateY(180deg);
}

.customShapeDividerBottom .shapeFill {
    fill: var(--powder-pink);
}

@media (max-width: 320px)  {
    .name {
        font-size: 30px;
    }

    .subText {
        font-size: 30px;
    }

    .landingText {
        font-size: 30px;
    }
}

@media (max-width: 426px)  {
    .name {
        font-size: 35px;
    }

    .subText {
        font-size: 35px;
    }

    .landingText {
        font-size: 35px;
    }
    .floatingShapesOne {
        display: none;
    }
    
    .floatingShapesTwo {
        display: none;
    }
    .profileImageContianer {
        display: none;
    }
}