* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  font-family: var(--font-sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #ffa600;
}

:root {
  --font-sans: "Calibre","San Francisco","SF Pro Text",-apple-system,system-ui,sans-serif;
  --font-mono: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
  --powder-pink: #ffa600;
  --dark-black-grey: #252627;
  --light-grey: #929292;
  --navbar-background: #252627e5;
  --navbar-shadow: #141414bf;
  --dark-powder-pink: #473204;
  --dark-powder-pink-opaque: #6b48085e;
  --card-shadow: 5px 5px 5px -3px #472f0493;
  --card-background-gradient: linear-gradient(to right bottom, #b67e068f, #2729292b);
  --common-transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
  --profile-pick-backdrop: linear-gradient(to right bottom, rgba(184, 144, 33, 0.959), rgba(39, 41, 41, 0.26));
  --button-hover-overlay: rgb(248, 182, 59);
  --white: #fff;
}
